<template>
  <v-container id="data-tables-view" fluid tag="section" class="pa-0">
    <v-col class="text-right pa-0">
      <app-btn @click="openDialog">
        <v-icon>mdi-plus-thick</v-icon> Add New Job
      </app-btn>
    </v-col>
    <v-row>
      <v-col cols="12" :md="mainCol">
        <material-card
          icon="mdi-account-group"
          icon-small
          color="primary"
          title="Jobs"
        >
          <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              hide-details
              label="Search records"
              single-line
              style="max-width: 250px"
            />

            <v-divider class="mt-3" />

            <div style="height: 465px; overflow: auto">
              <v-data-table
                :headers="headers"
                :items="jobs"
                :search.sync="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isDescending"
                :footer-props="{
                  showFirstLastPage: true,
                }"
                :items-per-page="5"
                single-expand
                :expanded.sync="expanded"
                item-key="jobId"
                show-expand
                @item-expanded="(data) => getDataForJob(data)"
              >
                <template v-slot:top>
                  <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form ref="JobForm">
                            <v-row>
                              <v-col cols="12" class="py-0 px-3">
                                <v-switch
                                  v-model="isFromTemplate"
                                  label="Create From Template"
                                ></v-switch>
                              </v-col>
                              <v-col
                                cols="12"
                                v-if="isFromTemplate && editedIndex === -1"
                              >
                                <v-autocomplete
                                  v-model="editedItem.jobTemplateId"
                                  :items="jobs"
                                  item-text="name"
                                  item-value="jobId"
                                  label="Job Template"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.jobNumber"
                                  label="Job Number"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="Name"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.reference"
                                  label="Reference"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.jobStartDate"
                                  label="Job Start Date"
                                />
                              </v-col>
                              <v-col cols="12" v-if="editedIndex > -1">
                                <v-text-field
                                  v-model="editedItem.jobEndDate"
                                  label="Job End Date"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogJobSection" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formJobSectionTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form ref="JobSectionForm">
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobSection.jobSectionName"
                                  label="Job Section Name"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobSection.reference"
                                  label="Reference"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobSection.slotTime"
                                  label="Slot Time"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="
                                    editedJobSection.manufacturingOverheads
                                  "
                                  label="Manufacturing Overheads"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeJobSection"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveJobSection"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogJobSlot" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formJobSlotTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form ref="JobSlotForm">
                            <v-row>
                              <v-col cols="12">
                                <v-autocomplete
                                  v-model="editedJobSlot.staffId"
                                  :items="staffs"
                                  item-text="staffName"
                                  item-value="staffId"
                                  label="Staff"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobSlot.costPerHour"
                                  label="Cost Per Hour"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobSlot.slotTimeInMinutes"
                                  label="Slot Time In Minutes"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobSlot.startTime"
                                  label="Start Time"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="blue darken-1" text @click="closeJobSlot">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="saveJobSlot">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogJobMaterial" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formJobMaterialTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form ref="JobMaterialForm">
                            <v-row>
                              <v-col cols="12">
                                <v-autocomplete
                                  v-model="editedJobMaterial.materialId"
                                  :items="materials"
                                  item-text="materialName"
                                  item-value="materialId"
                                  label="Material"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-autocomplete
                                  v-model="editedJobMaterial.locationId"
                                  :items="materialLocations"
                                  item-text="location.locationName"
                                  item-value="location.locationId"
                                  label="Location"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobMaterial.requiredQuantity"
                                  label="Required Quantity"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobMaterial.quantity"
                                  label="Quantity"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobMaterial.cost"
                                  label="Cost"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedJobMaterial.price"
                                  label="Price"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeJobMaterial"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveJobMaterial"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline">
                        Are you sure you want to delete this item?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="blue darken-1" text @click="closeDelete">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                        >
                          OK
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:[`item.jobStartDate`]="{ item }">
                  {{ formatTime(item.jobStartDate) }}
                </template>
                <template v-slot:[`item.jobEndDate`]="{ item }">
                  <div v-if="item.jobEndDate === '0001-01-01T00:00:00'"></div>
                  <div v-else>{{ formatTime(item.jobEndDate) }}</div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    medium
                    color="info"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon medium color="error" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="primary pa-1">
                    <v-data-table
                      :headers="sub_headers"
                      :items="item.jobSections"
                      single-expand
                      :expanded.sync="sectionExpanded"
                      item-key="jobSectionId"
                      show-expand
                      @item-expanded="(data) => getDataForJobSection(data)"
                    >
                      <template v-slot:[`header.actions`]>
                        <app-btn @click="openDialogJobSection(item)">
                          <v-icon>mdi-plus-thick</v-icon> Add New Job Section
                        </app-btn>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="secondary pa-1">
                          <v-data-table
                            :headers="slot_headers"
                            :items="item.jobSectionSlots"
                          >
                            <template v-slot:[`item.totalCost`]="{ item }">
                              <div>
                                {{ getTotalCost(item) }}
                              </div>
                            </template>
                            <template v-slot:[`header.actions`]>
                              <app-btn @click="openJobSlotDialog(item)">
                                <v-icon>mdi-plus-thick</v-icon> Add New Job Slot
                              </app-btn>
                            </template>
                          </v-data-table>
                          <!-- <v-card class="mt-1">
                          <v-card-text>
                            <div class="text-center">
                              <div>
                                {{ getTotalJobSlot(item.jobSectionSlots) }}
                              </div>
                              <div>
                                {{
                                  getTotalJobSlot(item.templateJobSectionSlots)
                                }}
                              </div>
                            </div>
                          </v-card-text>
                        </v-card> -->
                          <v-data-table
                            :headers="material_headers"
                            :items="item.jobSectionMaterials"
                            class="mt-1"
                          >
                            <template v-slot:[`header.actions`]>
                              <app-btn @click="openJobMaterialDialog(item)">
                                <v-icon>mdi-plus-thick</v-icon> Add New Job
                                Material
                              </app-btn>
                            </template>
                            <!-- <template v-slot:[`item.quantity`]="{ item }">
                            <div>text</div>
                         <template/> -->
                          </v-data-table>
                          <v-card class="mt-1">
                            <v-card-text>
                              <div class="text-center">
                                <div>
                                  {{
                                    getTotalJobMaterial(
                                      item.jobSectionMaterials
                                    )
                                  }}
                                </div>

                                <div>
                                  {{
                                    getTotalJobMaterial(
                                      item.templateJobSectionMaterials
                                    )
                                  }}
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </td>
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </material-card>
      </v-col>
      <v-col cols="12" :md="detailCol" class="mt-4" v-if="isDetails">
        <div style="height: 600px; overflow: auto">
          <v-card>
            <v-card-title><h2>Details</h2></v-card-title>
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <h3>Job Section</h3>
                  <div>Name: {{ comparisonJobSection.jobSectionName }}</div>
                  <div>
                    Overheads: {{ comparisonJobSection.manufacturingOverheads }}
                  </div>
                  <div>Slot Time: {{ comparisonJobSection.slotTime }}</div>
                </v-col>
                <v-col cols="12"
                  ><h3>Time Slot</h3>
                  <div>ActTime: {{ comparisonJobSlot.time }}</div>
                  <div>TmplTime: {{ comparisonJobSlot.actualTime }}</div>
                  <div>ActCost: {{ comparisonJobSlot.cost }}</div>
                  <div>TmplCost: {{ comparisonJobSlot.actualCost }}</div>
                </v-col>
                <v-col cols="12"
                  ><h3>Material</h3>
                  <v-data-table
                    :headers="material_c_headers"
                    :items="comparisonMaterialItems"
                  >
                    <template v-slot:[`item.tem_quantity`]="{ item }">
                      <div>
                        {{ getMaterialTemplateQuantity(item.materialId) }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import * as moment from "moment/moment";

export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogJobSection: false,
    dialogJobSlot: false,
    dialogJobMaterial: false,
    dialogDelete: false,
    mainCol: 12,
    detailCol: 0,
    isDetails: false,
    headers: [
      {
        text: "Job Number",
        align: "start",
        value: "jobNumber",
      },
      {
        text: "Name",
        value: "name",
      },
      { text: "Reference", value: "reference" },
      { text: "Job Start Date", value: "jobStartDate" },
      { text: "Job End Date", value: "jobEndDate" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sub_headers: [
      { text: "Job Section Name", align: "start", value: "jobSectionName" },
      {
        text: "Manufacturing Overheads",
        value: "manufacturingOverheads",
      },
      { text: "Slot Time", value: "slotTime" },
      { text: "Actions", align: "end", sortable: false, value: "actions" },
    ],
    slot_headers: [
      { text: "Staff Number", align: "start", value: "staff.staffNumber" },
      {
        text: "Staff Code",
        value: "staff.staffCode",
      },
      { text: "Staff Name", value: "staff.staffName" },
      { text: "Phone", value: "staff.phone" },
      { text: "Cost Per Hour", value: "costPerHour" },
      { text: "Slot Time In Minutes", value: "slotTimeInMinutes" },
      { text: "Total", value: "totalCost" },
      { text: "Actions", align: "end", sortable: false, value: "actions" },
    ],
    material_headers: [
      {
        text: "Ean",
        align: "start",
        value: "material.ean",
      },
      { text: "Material Name", value: "material.materialName" },
      {
        text: "SKU",
        value: "material.materialSKU",
      },
      // {
      //   text: "Description",
      //   value: "material.meterialDescription",
      // },
      // {
      //   text: "Price",
      //   value: "material.price",
      // },
      // {
      //   text: "Cost",
      //   value: "material.cost",
      // },
      // {
      //   text: "Minimum Measurement",
      //   value: "material.minimumMeasurement",
      // },
      // {
      //   text: "Fixed Measurement",
      //   value: "material.fixedMeasurement",
      // },
      // {
      //   text: "Location Code",
      //   value: "location.locationCode",
      // },
      {
        text: "Location Name",
        value: "location.locationName",
      },
      {
        text: "Required Quantity",
        value: "requiredQuantity",
      },
      {
        text: "Quantity",
        value: "quantity",
      },
      {
        text: "Cost",
        value: "cost",
      },
      {
        text: "Price",
        value: "price",
      },
      { text: "Actions", align: "end", sortable: false, value: "actions" },
    ],
    material_c_headers: [
      {
        text: "Desc",
        value: "material.materialName",
      },
      {
        text: "ActQty",
        value: "quantity",
      },
      {
        text: "TmpQty",
        value: "tem_quantity",
      },
    ],
    sortBy: "jobId",
    isDescending: true,
    jobs: [],
    materials: [],
    materialLocations: [],
    staffs: [],
    expanded: [],
    sectionExpanded: [],
    isFromTemplate: false,
    editedIndex: -1,
    editedJobSectionIndex: -1,
    editedJobSlotIndex: -1,
    editedJobMaterialIndex: -1,
    comparisonJobSection: {
      jobSectionName: "",
      ManufacturingOverHeads: "",
      slotTime: "",
    },
    comparisonJobSlot: {
      actualTime: 0,
      time: 0,
      actualCost: 0,
      cost: 0,
    },
    comparisonMaterialItems: [],
    comparisonMaterialTemplateItems: [],
    comparisonJobSlotItems: [],
    comparisonJobSlotTemplateItems: [],
    editedItem: {
      jobTemplateId: 0,
      jobNumber: "",
      name: "",
      reference: "",
      jobStartDate: "",
      jobEndDate: "",
    },
    defaultItem: {
      jobTemplateId: 0,
      jobNumber: "",
      name: "",
      reference: "",
      jobStartDate: "",
      jobEndDate: "",
    },
    editedJobSection: {
      jobId: "",
      jobSectionName: "",
      reference: "",
      slotTime: "",
      manufacturingOverheads: "",
    },
    defaultJobSection: {
      jobId: "",
      jobSectionName: "",
      reference: "",
      slotTime: "",
      manufacturingOverheads: "",
    },
    editedJobSlot: {
      jobId: 0,
      jobSectionId: 0,
      staffId: 0,
      costPerHour: "",
      slotTimeInMinutes: "",
      startTime: "",
    },
    defaultJobSlot: {
      jobId: 0,
      jobSectionId: 0,
      staffId: 0,
      costPerHour: "",
      slotTimeInMinutes: "",
      startTime: "",
    },
    editedJobMaterial: {
      jobId: 0,
      jobSectionId: 0,
      materialId: 0,
      locationId: 0,
      requiredQuantity: "",
      quantity: "",
      cost: "",
      price: "",
    },
    defaultJobMaterial: {
      jobId: 0,
      jobSectionId: 0,
      materialId: 0,
      locationId: 0,
      requiredQuantity: "",
      quantity: "",
      cost: "",
      price: "",
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Job" : "Edit Job";
    },
    formJobSectionTitle() {
      return this.editedJobSectionIndex === -1
        ? "Add New Job Section"
        : "Edit Job Section";
    },
    formJobSlotTitle() {
      return this.editedJobSlotIndex === -1
        ? "Add New Job Slot"
        : "Edit Job Slot";
    },
    formJobMaterialTitle() {
      return this.editedJobMaterialIndex === -1
        ? "Add New Job Material"
        : "Edit Job Material";
    },
    checkEditedJobMaterial() {
      return this.editedJobMaterial.materialId;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogJobSection(val) {
      val || this.closeJobSection();
    },
    dialogJobSlot(val) {
      val || this.closeJobSlot();
    },
    dialogJobMaterial(val) {
      val || this.closeJobMaterial();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    checkEditedJobMaterial: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          let item = this.materials.find((x) => x.materialId === val);
          if (item) {
            this.materialLocations = item.materialLocations;
            console.log(this.materialLocations);
          }
        }
      },
      deep: true,
    },
    comparisonJobSlotTemplateItems: {
      handler() {
        let actual = this.getTotalJobSlot(this.comparisonJobSlotTemplateItems);

        this.comparisonJobSlot.actualTime = actual.totalTime + "h";
        this.comparisonJobSlot.actualCost = "$" + actual.totalCost;
      },
    },
    comparisonJobSlotItems: {
      handler() {
        let item = this.getTotalJobSlot(this.comparisonJobSlotItems);

        this.comparisonJobSlot.time = item.totalTime + "h";
        this.comparisonJobSlot.cost = "$" + item.totalCost;
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("Job/GetAllWithJobSections").then((response) => {
        this.jobs = response.data;
      });
      this.$http.get("/Staff").then((response) => {
        this.staffs = response.data;
      });
      this.$http.get("/Material/GetAllWithLocations").then((response) => {
        this.materials = response.data.data;
      });
    },
    openDialog() {
      this.editedItem.jobStartDate = new Date();
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.jobs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.jobStartDate = new Date(
        Date.parse(this.editedItem.jobStartDate)
      );
      this.editedItem.jobEndDate = new Date();
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.jobs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let jobIndex = this.editedIndex;
      this.$http
        .get("/Job/Trash", {
          params: {
            id: this.editedItem.jobId,
          },
        })
        .then((response) => {
          if (response.data.isSuccess) {
            this.jobs.splice(jobIndex, 1);
            this.$toast.success("Job deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(
              "Something went wrong while deleting the job.",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        });
      this.closeDelete();
    },
    save() {
      if (this.$refs.JobForm.validate()) {
        let jobIndex = this.editedIndex;
        if (jobIndex > -1) {
          this.$http.patch("/Job/Update", this.editedItem).then((response) => {
            if (response.status === 200) {
              this.jobs.splice(jobIndex, 1, response.data);
              this.$toast.success("Job Updated successfully.", "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(
                "Something went wrong while updating the job.",
                "Error",
                {
                  position: "topRight",
                }
              );
            }
          });
        } else {
          this.editedItem.jobEndDate = "0001-01-01T00:00:00";
          if (this.isFromTemplate) {
            this.$http
              .post("/Job/CreateJobFromTemplate", this.editedItem)
              .then((response) => {
                if (response.data.success) {
                  this.jobs.unshift(response.data.data);
                  this.$toast.success("Job added successfully.", "Success", {
                    position: "topRight",
                  });
                } else {
                  this.$toast.error(
                    "Something went wrong while adding the job.",
                    "Error",
                    {
                      position: "topRight",
                    }
                  );
                }
              });
          } else {
            this.$http.post("/Job", this.editedItem).then((response) => {
              if (response.data.isSuccess) {
                response.data.outputObject.jobSections = [];
                this.jobs.unshift(response.data.outputObject);
                this.$toast.success("Job added successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while adding the job.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
          }
        }
        this.close();
      }
    },
    openDialogJobSection(item) {
      this.editedJobSection.jobId = item.jobId;
      this.dialogJobSection = true;
    },
    saveJobSection() {
      if (this.$refs.JobSectionForm.validate()) {
        let jobSectionIndex = this.editedJobSectionIndex;
        if (jobSectionIndex > -1) {
          this.$http
            .patch("/Job/Update", this.editedJobSection)
            .then((response) => {
              if (response.status === 200) {
                this.jobs.splice(jobSectionIndex, 1, response.data);
                this.$toast.success("Job Updated successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while updating the job.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        } else {
          this.$http
            .post("/JobSection/Create", this.editedJobSection)
            .then((response) => {
              if (response.data.isSuccess) {
                this.jobs.unshift(response.data.outputObject);
                this.$toast.success(
                  "Job section added successfully.",
                  "Success",
                  {
                    position: "topRight",
                  }
                );
              } else {
                this.$toast.error(
                  "Something went wrong while adding the job section.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        }
        this.close();
      }
    },
    getDataForJob(data) {
      if (!data.value) {
        this.mainCol = 12;
        this.detailCol = 0;
        this.isDetails = false;
      }
    },
    getDataForJobSection(data) {
      if (data.value) {
        if (!data.item.hasOwnProperty("jobSectionSlots")) {
          this.$http
            .post("/JobSlot/GetJobSlotsByJobSectionId", {
              jobSectionId: data.item.jobSectionId,
            })
            .then((response) => {
              this.pushDataToJobSlot(
                data.item.jobId,
                data.item.jobSectionId,
                response.data.data
              );
              this.comparisonJobSlotItems = [];
              this.comparisonJobSlotItems = response.data.data;
            });
        } else {
          this.comparisonJobSlotItems = [];
          this.comparisonJobSlotItems = data.item.jobSectionSlots;
        }
        if (!data.item.hasOwnProperty("jobSectionMaterials")) {
          this.$http
            .post("/JobMaterial/GetJobMaterialsByJobSectionId", {
              jobSectionId: data.item.jobSectionId,
            })
            .then((response) => {
              this.pushDataToJobMaterial(
                data.item.jobId,
                data.item.jobSectionId,
                response.data.data
              );
              this.comparisonMaterialItems = [];
              this.comparisonMaterialItems = response.data.data;
            });
        } else {
          this.comparisonMaterialItems = [];
          this.comparisonMaterialItems = data.item.jobSectionMaterials;
        }
        if (data.item.templateJobSectionId != null) {
          if (!data.item.hasOwnProperty("templateJobSectionSlots")) {
            this.$http
              .post("/JobSlot/GetJobSlotsByJobSectionId", {
                jobSectionId: data.item.templateJobSectionId,
              })
              .then((response) => {
                this.pushDataToTemplateJobSlot(
                  data.item.jobId,
                  data.item.jobSectionId,
                  response.data.data
                );
                this.comparisonJobSlotTemplateItems = [];
                this.comparisonJobSlotTemplateItems = response.data.data;
              });
          } else {
            this.comparisonJobSlotTemplateItems = [];
            this.comparisonJobSlotTemplateItems =
              data.item.templateJobSectionSlots;
          }
          if (!data.item.hasOwnProperty("templateJobSectionMaterials")) {
            this.$http
              .post("/JobMaterial/GetJobMaterialsByJobSectionId", {
                jobSectionId: data.item.templateJobSectionId,
              })
              .then((response) => {
                this.pushDataToTemplateJobMaterial(
                  data.item.jobId,
                  data.item.jobSectionId,
                  response.data.data
                );
                this.comparisonMaterialTemplateItems = [];
                this.comparisonMaterialTemplateItems = response.data.data;
              });
          } else {
            this.comparisonMaterialTemplateItems = [];
            this.comparisonMaterialTemplateItems =
              data.item.templateJobSectionMaterials;
          }

          this.comparisonJobSection.jobSectionName = data.item.jobSectionName;
          this.comparisonJobSection.manufacturingOverheads =
            data.item.manufacturingOverheads;
          this.comparisonJobSection.slotTime = data.item.slotTime;

          this.mainCol = 9;
          this.detailCol = 3;
          this.isDetails = true;
        }
      } else {
        this.mainCol = 12;
        this.detailCol = 0;
        this.isDetails = false;
      }
    },
    getJobSlotDetails() {
      let actual = this.getTotalJobSlot(this.comparisonJobSlotTemplateItems);
      let item = this.getTotalJobSlot(this.comparisonJobSlotItems);

      this.comparisonJobSlot.actualTime = actual.totalTime;
      this.comparisonJobSlot.actualCost = actual.totalCost;
      this.comparisonJobSlot.time = item.totalCost;
      this.comparisonJobSlot.cost = item.totalCost;
    },
    openJobSlotDialog(item) {
      this.editedJobSlot.jobId = item.jobId;
      this.editedJobSlot.jobSectionId = item.jobSectionId;
      this.editedJobSlot.startTime = new Date();
      this.dialogJobSlot = true;
    },
    saveJobSlot() {
      if (this.$refs.JobSlotForm.validate()) {
        let jobSlotIndex = this.editedJobSlotIndex;
        let item = this.editedJobSlot;
        if (jobSlotIndex > -1) {
          this.$http
            .patch("/Job/Update", this.editedJobSection)
            .then((response) => {
              if (response.status === 200) {
                this.jobs.splice(jobSlotIndex, 1, response.data);
                this.$toast.success("Job Updated successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while updating the job.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        } else {
          this.$http
            .post("/JobSlot/Create", this.editedJobSlot)
            .then((response) => {
              if (response.data.isSuccess) {
                this.pushDataToJobSlot(item.jobId, item.jobSectionId, [
                  response.data.outputObject,
                ]);
                this.$toast.success("Job Slot added successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while adding the job slot.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        }
        this.closeJobSlot();
      }
    },
    openJobMaterialDialog(item) {
      this.editedJobMaterial.jobId = item.jobId;
      this.editedJobMaterial.jobSectionId = item.jobSectionId;
      this.dialogJobMaterial = true;
    },
    saveJobMaterial() {
      if (this.$refs.JobMaterialForm.validate()) {
        let jobMaterialIndex = this.editedJobMaterialIndex;
        let item = this.editedJobMaterial;
        if (jobMaterialIndex > -1) {
          this.$http
            .patch("/Job/Update", this.editedJobSection)
            .then((response) => {
              if (response.status === 200) {
                this.jobs.splice(jobMaterialIndex, 1, response.data);
                this.$toast.success("Job Updated successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while updating the job.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        } else {
          this.$http
            .post("/JobMaterial/Create", this.editedJobMaterial)
            .then((response) => {
              if (response.data.isSuccess) {
                this.pushDataToJobMaterial(item.jobId, item.jobSectionId, [
                  response.data.outputObject,
                ]);
                this.$toast.success(
                  "Job Material added successfully.",
                  "Success",
                  {
                    position: "topRight",
                  }
                );
              } else {
                this.$toast.error(
                  "Something went wrong while adding the job material.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        }
        this.closeJobMaterial();
      }
    },
    getTotalCost(item) {
      let cost = (item.slotTimeInMinutes / 60) * item.costPerHour;
      return "$" + parseFloat(cost).toFixed(2);
    },
    getTotalJobSlot(jobSectionSlots) {
      var totalCost = 0;
      var totalTime = 0;
      if (jobSectionSlots) {
        jobSectionSlots.forEach((x) => {
          let cost = (x.slotTimeInMinutes / 60) * x.costPerHour;
          totalCost += cost;
          totalTime += x.slotTimeInMinutes;
        });
      }

      return {
        totalCost: parseFloat(totalCost).toFixed(2),
        totalTime: parseFloat(totalTime / 60).toFixed(0),
      };
      // (
      //   "Total Slot Time: " +
      //   parseFloat(totalTime).toFixed(0) +
      //   " hours - Total Cost: " +
      //   "$" +
      //   parseFloat(totalCost).toFixed(2)
      // );
    },
    getTotalJobMaterial(jobSectionMaterials) {
      var totalCost = 0;
      var totalPrice = 0;
      if (jobSectionMaterials) {
        jobSectionMaterials.forEach((x) => {
          totalCost += x.cost;
          totalPrice += x.price;
        });
      }
      return (
        "Total Cost: $" +
        parseFloat(totalCost).toFixed(2) +
        " - Total Price: $" +
        parseFloat(totalPrice).toFixed(2)
      );
    },
    pushDataToJobSlot(jobId, sectionId, output) {
      // console.log(jobId, sectionId, output);
      if (jobId != null && sectionId != null && output != null) {
        let jobIndex = this.jobs.findIndex((x) => x.jobId === jobId);
        let jobSectionIndex = this.jobs[jobIndex].jobSections.findIndex(
          (x) => x.jobSectionId === sectionId
        );
        if (
          !this.jobs[jobIndex].jobSections[jobSectionIndex].hasOwnProperty(
            "jobSectionSlots"
          )
        ) {
          this.$set(
            this.jobs[jobIndex].jobSections[jobSectionIndex],
            "jobSectionSlots",
            output
          );
        } else {
          if (output.length > 0) {
            this.jobs[jobIndex].jobSections[
              jobSectionIndex
            ].jobSectionSlots.push(output[0]);
          }
        }
      }
    },
    pushDataToTemplateJobSection(jobId, sectionId, output) {
      // console.log(jobId, sectionId, output);
      if (jobId != null && sectionId != null && output != null) {
        let jobIndex = this.jobs.findIndex((x) => x.jobId === jobId);
        let jobSectionIndex = this.jobs[jobIndex].jobSections.findIndex(
          (x) => x.jobSectionId === sectionId
        );
        if (
          !this.jobs[jobIndex].jobSections[jobSectionIndex].hasOwnProperty(
            "_templateJobSection"
          )
        ) {
          this.$set(
            this.jobs[jobIndex].jobSections[jobSectionIndex],
            "_templateJobSection",
            output
          );
        }
      }
    },
    pushDataToTemplateJobSlot(jobId, sectionId, output) {
      // console.log(jobId, sectionId, output);
      if (jobId != null && sectionId != null && output != null) {
        let jobIndex = this.jobs.findIndex((x) => x.jobId === jobId);
        let jobSectionIndex = this.jobs[jobIndex].jobSections.findIndex(
          (x) => x.jobSectionId === sectionId
        );
        if (
          !this.jobs[jobIndex].jobSections[jobSectionIndex].hasOwnProperty(
            "templateJobSectionSlots"
          )
        ) {
          this.$set(
            this.jobs[jobIndex].jobSections[jobSectionIndex],
            "templateJobSectionSlots",
            output
          );
        } else {
          if (output.length > 0) {
            this.jobs[jobIndex].jobSections[
              jobSectionIndex
            ].jobSectionSlots.push(output[0]);
          }
        }
      }
    },
    pushDataToTemplateJobMaterial(jobId, sectionId, output) {
      // console.log(jobId, sectionId, output);
      if (jobId != null && sectionId != null && output != null) {
        let jobIndex = this.jobs.findIndex((x) => x.jobId === jobId);
        let jobSectionIndex = this.jobs[jobIndex].jobSections.findIndex(
          (x) => x.jobSectionId === sectionId
        );
        if (
          !this.jobs[jobIndex].jobSections[jobSectionIndex].hasOwnProperty(
            "templateJobSectionMaterials"
          )
        ) {
          this.$set(
            this.jobs[jobIndex].jobSections[jobSectionIndex],
            "templateJobSectionMaterials",
            output
          );
        } else {
          if (output.length > 0) {
            this.jobs[jobIndex].jobSections[
              jobSectionIndex
            ].templateJobSectionMaterials.push(output[0]);
          }
        }
      }
    },
    pushDataToJobMaterial(jobId, sectionId, output) {
      // console.log(jobId, sectionId, output);
      if (jobId != null && sectionId != null && output != null) {
        let jobIndex = this.jobs.findIndex((x) => x.jobId === jobId);
        let jobSectionIndex = this.jobs[jobIndex].jobSections.findIndex(
          (x) => x.jobSectionId === sectionId
        );
        if (
          !this.jobs[jobIndex].jobSections[jobSectionIndex].hasOwnProperty(
            "jobSectionMaterials"
          )
        ) {
          this.$set(
            this.jobs[jobIndex].jobSections[jobSectionIndex],
            "jobSectionMaterials",
            output
          );
        } else {
          if (output.length > 0) {
            this.jobs[jobIndex].jobSections[
              jobSectionIndex
            ].jobSectionMaterials.push(output[0]);
          }
        }
      }
    },
    getMaterialTemplateQuantity(materialId) {
      if (
        this.comparisonMaterialTemplateItems != null &&
        this.comparisonMaterialTemplateItems.length > 0
      ) {
        let material = this.comparisonMaterialTemplateItems.find(
          (x) => x.materialId === materialId
        );
        if (material) {
          return material.quantity;
        } else {
          return "Material NIL";
        }
      } else {
        return "No Template";
      }
    },
    formatTime(dateTime) {
      return moment(dateTime).format("MMMM Do YYYY, h:mm:ss a");
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeJobSection() {
      this.dialogJobSection = false;
      this.$nextTick(() => {
        this.editedJobSection = Object.assign({}, this.defaultJobSection);
        this.editedJobSectionIndex = -1;
      });
    },
    closeJobSlot() {
      this.dialogJobSlot = false;
      this.$nextTick(() => {
        this.editedJobSlot = Object.assign({}, this.defaultJobSlot);
        this.editedJobSlotIndex = -1;
      });
    },
    closeJobMaterial() {
      this.dialogJobMaterial = false;
      this.$nextTick(() => {
        this.editedJobMaterial = Object.assign({}, this.defaultJobMaterial);
        this.editedJobMaterialIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
